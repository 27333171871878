import resources from '../resources/resources';

let constants = {
  autenticacaoServerAddress: '',
  utilServerAddress: '',
  administracaoServerAddress: '',
  geralServerAddress: '',
  devOpsServerAddress: '',
  whatsAppServerAddress: '',
  chatpierServerAddress: '',
  telephonyServerAddress: '',
  typebotBaseUrl: '',
  campaignsWhatsappServerAdress: '',
};

switch (resources.version.name) {
  case '999.0.0': // Dev
    constants.autenticacaoServerAddress =
      'https://9ky8dbc159.execute-api.us-east-1.amazonaws.com/Prod';
    constants.whatsAppServerAddress =
      'https://sys293vl5c.execute-api.us-east-1.amazonaws.com/Prod/api';
    constants.administracaoServerAddress =
      'https://oqv2aq2b00.execute-api.us-east-1.amazonaws.com/Prod/api';
    constants.utilServerAddress =
      'https://z2je32nj8e.execute-api.us-east-1.amazonaws.com/Prod/api';
    constants.geralServerAddress =
      'https://m6i9b012f9.execute-api.us-east-1.amazonaws.com/Prod/api';
    constants.devOpsServerAddress =
      'https://3m1vcfgpvc.execute-api.us-east-1.amazonaws.com/Prod/api';
    constants.chatpierServerAddress =
      'https://i497mdf4fa.execute-api.us-east-1.amazonaws.com/Prod';
    constants.telephonyServerAddress =
      'https://q0jz5kowq2.execute-api.us-east-1.amazonaws.com/Prod';
    constants.campaignsWhatsappServerAdress =
      'https://b5zcktvfx4.execute-api.us-east-1.amazonaws.com/Prod/api';

    break;
  default:
    // Prod 1.0, 1.2.0, 1.3.0, etc
    constants.autenticacaoServerAddress =
      'https://m4ut39vv4k.execute-api.us-east-2.amazonaws.com/Prod/';
    constants.utilServerAddress =
      'https://ynum2de9ce.execute-api.us-east-2.amazonaws.com/Prod/api';
    constants.administracaoServerAddress =
      'https://tpwmzhi499.execute-api.us-east-2.amazonaws.com/Prod/api';
    constants.geralServerAddress =
      'https://62wgvyxr1c.execute-api.us-east-2.amazonaws.com/Prod/api';
    constants.devOpsServerAddress =
      'https://okd2d480td.execute-api.us-east-2.amazonaws.com/Prod/api';
    constants.whatsAppServerAddress =
      'https://klf96pqf78.execute-api.us-east-2.amazonaws.com/Prod/api';
    constants.chatpierServerAddress =
      'https://7u3kr0zlj9.execute-api.us-east-2.amazonaws.com/Prod';
    constants.telephonyServerAddress =
      'https://f05kiu1q07.execute-api.us-east-2.amazonaws.com/Prod';
    constants.campaignsWhatsappServerAdress =
      'https://oqz6la3vw5.execute-api.us-east-2.amazonaws.com/Prod/api';

    break;
}

export default constants;
