import router from '../../..';
import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');
const IndexWhatsapp = () =>
  import('../campaignsWhatsapp/IndexCampaignsWhatsapp.vue');
const whatsappChannels = () =>
  import('../campaignsWhatsapp/ChannelsCampaingsWhatsapp.vue');
const informationCampaigns = () =>
  import('../campaignsWhatsapp/stepsCampaignsWhatsapp/stepOne.vue');
const targetPublicWhatsapp = () =>
  import('../campaignsWhatsapp/stepsCampaignsWhatsapp/stepTwo.vue');
const ProviderCampaigns = () =>
  import('../campaignsWhatsapp/stepsCampaignsWhatsapp/stepThree.vue');
const messageCampaigns = () =>
  import('../campaignsWhatsapp/stepsCampaignsWhatsapp/stepFour.vue');
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'globe-desktop',
      },
      children: [
        {
          path: '',
          redirect: 'ongoing',
        },
        {
          path: 'ongoing',
          name: 'ongoing_campaigns',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
        icon: 'comment-note',
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.WHATSAPP.HEADER',
        icon: 'whatsapp',
      },
      children: [
        {
          path: 'whatsapp',
          name: 'whatsapp',
          meta: {
            permissions: ['administrator'],
          },
          children: [],
          component: IndexWhatsapp,
        },
        {
          path: 'new',
          component: whatsappChannels,
          children: [
            {
              path: 'step_one',
              name: 'settings_campaigns_whatsapp_new_stepOne',
              component: informationCampaigns,
              beforeEnter: (to, from, next) => {
                if (
                  (to.name == 'settings_campaigns_whatsapp_new_stepOne' &&
                    from.name == 'whatsapp') ||
                  from.name == 'target_public_new_stepTwo'
                ) {
                  return next();
                } else {
                  router.push(
                    frontendURL(
                      `accounts/${to.params.accountId}/campaigns/whatsapp`
                    )
                  );
                }
              },
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: 'step_two',
              name: 'target_public_new_stepTwo',
              component: targetPublicWhatsapp,
              beforeEnter: (to, from, next) => {
                if (
                  (to.name == 'target_public_new_stepTwo' &&
                    from.name == 'settings_campaigns_whatsapp_new_stepOne') ||
                  from.name == 'provider_new_stepThree'
                ) {
                  return next();
                } else {
                  router.push(
                    frontendURL(
                      `accounts/${to.params.accountId}/campaigns/whatsapp`
                    )
                  );
                }
              },
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: 'step_three',
              name: 'provider_new_stepThree',
              component: ProviderCampaigns,
              beforeEnter: (to, from, next) => {
                if (
                  (to.name == 'provider_new_stepThree' &&
                    from.name == 'target_public_new_stepTwo') ||
                  from.name == 'message_new_stepFour'
                ) {
                  return next();
                } else {
                  router.push(
                    frontendURL(
                      `accounts/${to.params.accountId}/campaigns/whatsapp`
                    )
                  );
                }
              },
              meta: {
                permissions: ['administrator'],
              },
            },
            {
              path: 'step_four',
              name: 'message_new_stepFour',
              component: messageCampaigns,
              beforeEnter: (to, from, next) => {
                if (
                  (to.name == 'message_new_stepFour' &&
                    from.name == 'provider_new_stepThree') ||
                  from.name == 'message_new_stepFour'
                ) {
                  return next();
                } else {
                  router.push(
                    frontendURL(
                      `accounts/${to.params.accountId}/campaigns/whatsapp`
                    )
                  );
                }
              },
              meta: {
                permissions: ['administrator'],
              },
            },
          ],
        },
      ],
    },
  ],
};
