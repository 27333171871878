import { LocalStorage } from 'shared/helpers/localStorage';
import { LOCAL_STORAGE_KEYS } from 'dashboard/constants/localStorage';

const setDarkMode = theme => {
  const frameElement = document.getElementById(`csml-editor--frame`);
  // eslint-disable-next-line no-unused-expressions
  frameElement
    ? frameElement.contentWindow.postMessage(
        { theme: theme === 'auto' ? 'system' : theme },
        '*'
      )
    : null;
};

export const setColorTheme = isOSOnDarkMode => {
  const selectedColorScheme =
    LocalStorage.get(LOCAL_STORAGE_KEYS.COLOR_SCHEME) || 'auto';
  if (
    (selectedColorScheme === 'auto' && isOSOnDarkMode) ||
    selectedColorScheme === 'dark'
  ) {
    document.body.classList.add('dark');
    document.documentElement.setAttribute('style', 'color-scheme: dark;');
    setDarkMode('dark');
  } else {
    document.body.classList.remove('dark');
    document.documentElement.setAttribute('style', 'color-scheme: light;');
    setDarkMode('light');
  }
};
