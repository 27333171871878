import avonaleAPIHelper from '../../../../helper/avonaleAPIHelper';
import constants from '../../../../resources/constants';

import axios from 'axios';

const API = axios.create({
  baseURL: constants.campaignsWhatsappServerAdress,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    crossdomain: true,
    'Access-Control-Allow-Origin': '*',
  },
});

API.interceptors.request.use(avonaleAPIHelper, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

async function createCampaign(payload) {
  return API.post(`/Campanha/InserirCampanha`, payload);
}
async function CreateProviderCampaign(providerObj) {
  return API.post(`/Campanha/Provider/InserirProvider`, providerObj);
}
async function getAllCampaigns(accountId) {
  return API.get(`/Campanha/ListarCampanhas`, {
    params: { AccountId: accountId },
  });
}
async function getAllProviders(accountId) {
  return API.get(`/Campanha/Provider/ListarProviders/${accountId}`);
}
async function removeCampaigns(campaignsId) {
  return API.delete(`/Campanha/RemoverCampanha/${campaignsId}`);
}
async function updateCampaign(campaignObj) {
  return API.put(`/Campanha/EditarCampanha`, campaignObj);
}
async function updateStatusCampaign(campaignObj) {
  return API.put(`/Campanha/AtualizarStatusDaCampanha`, campaignObj);
}
async function removeProvider(providerId) {
  return API.delete(`/Campanha/Provider/RemoverProvider/${providerId}`);
}

export {
  createCampaign,
  getAllCampaigns,
  removeCampaigns,
  updateCampaign,
  updateStatusCampaign,
  CreateProviderCampaign,
  getAllProviders,
  removeProvider,
};
